*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

h1,
h2,
h3,
p {
  margin: 0;
}

.container {
  width: 100%;
  padding: 40px;
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: rgb(27, 27, 27);
  color: rgb(173, 173, 173);
}
.f-text {
  transition: 0.5s;
}

.f-text:visited {
  color: rgb(160, 160, 160);
} /* Visited link    */
.f-text:hover {
  color: rgb(255, 255, 255);
}
.f-text:active {
  color: rgb(160, 160, 160);
} /* Selected link   */

/* #btn-top {
  display: none;
  position: fixed;
  bottom: 20px;
  z-index: 1000;
  right: 30px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

#arrow {
  position: relative;
  width: 50px;
} */
